import {endpoint, fetch_options} from "./helper";

export function getCountriesAPI() {
    return fetch(endpoint+'/countries',fetch_options).then(r=>r.json());
}
export function getCountryAPI(country_id) {
    return fetch(`${endpoint}/countries/${country_id}`,fetch_options).then(r=>r.json());
}

export function addCountryAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/countries`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateCountryAPI(_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/countries/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

export function deleteCountryAPI(_id){
    return fetch(`${endpoint}/countries/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}


export function importCountriesFromApp1API() {
    return fetch(`${endpoint}/countries/import-from-app1`,{...fetch_options, method:'POST'} ).then(r=>r.json());
}