import React from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import logo from "./images/logo_w.svg"
const LeftMenu = () =>{
    const location = useLocation();
   // const router = useRouter();
    const isActive= (link:string) =>{
        let has = location.pathname===link;
        if(!has){
            has = location.pathname.indexOf(link)===0;
        }
        return (has===true)?'width-icn active':'width-icn'
    }

    return(
        <div className="col-auto bar-col">
            <div className="row g-0 flex-column h-100">
                <nav className="main-nav col">
                    <Link to={"/customers"} className={isActive('/customers')}>
                        <i className='fad fa-users'></i> Customers
                    </Link>
                    <Link to={"/modems"} className={isActive('/modems')}>
                        <i className='fad fa-router'></i> Modems
                    </Link>
                    <Link to={"/ports"} className={isActive('/ports')}>
                        <i className='fal fa-network-wired'></i> Ports
                    </Link>
                    <Link to={"/servers"} className={isActive('/servers')}>
                        <i className='fad fa-server'></i> Servers
                    </Link>
                    <Link to={"/countries"} className={isActive('/countries')}>
                        <i  className='fad fa-globe-europe'></i> Countries
                    </Link>
                    <Link to={"/tariffs"} className={isActive('/tariffs')}>
                        <i  className='fad fa-dollar-sign'></i> Tariffs
                    </Link>

                </nav>
                <div className="footer col-auto">
                    <img src={logo} alt="Coronium"/>
                    <div className="copy">© 2020 ALL RIGHTS RESERVED.</div>
                </div>
            </div>
        </div>
    )
}

export default LeftMenu;