import {io} from  "socket.io-client";
const delay = ms => new Promise(resolve=>setTimeout(resolve,ms));
const Sockets = (function () {
    "use strict";
    let instance; //prevent modification of "instance" variable
    function Singleton() {
        if (instance) {
            return instance;
        }
        instance = this;
        const init = (endpoint,token)=>{
            //console.log('init')
            const _socket = io(endpoint, {
                extraHeaders: { Authorization: `Bearer ${token}` },
                query: `token=${token}`
            });
            _socket.on('connect', () => {
                //      console.log('connected!');
            });
            _socket.onAny((_event, data) => {
                for (let {event, cb} of this.callbacks) {
                    if (cb && _event === event) {
                        cb(data);
                    }
                }
            });
            _socket.on("connect_error", error => {
                if ( error?.data?.code == 'invalid_token') {
                    _socket.error=new Error('Invalid token');
                }else{
                  //  console.log(error.message)
                    _socket.error=error;
                }

            });
            return _socket;
        }
        //Singleton initialization code
        this.callbacks = [];

        this.connect = async function(endpoint,token){
            try {
                if(this?.socket?.connected){
                    return Promise.resolve();
                }
                this.socket=init(endpoint,token);
                await delay(300);
                if(this?.socket?.connected){
                    return Promise.resolve();
                }else {
                    return Promise.resolve(this.socket.error)
                }
            }catch (err){
                return Promise.resolve(err)
            }
           /* return new Promise((resolve, reject)=>{
                if(this?.socket?.connected){
                    return resolve();
                }
                this.socket=init(endpoint,token);
                setTimeout(()=>{
                    if(this?.socket?.connected){
                        return resolve();
                    }else {
                        resolve(this.socket.error)
                    }
                },300)
            })*/
        }
        this.disconnect = ()=>this.socket&&this.socket.disconnect();

        this.isConnected = ()=> this?.socket?.connected;

        this.addListener = (event, cb) => {
            if (!this.callbacks.find(f => f.cb === cb && f.event === event)) {
                this.callbacks.push({event, cb});
            }
        }
        this.removeListener =  (event, cb) => {
            this.callbacks = this.callbacks.filter(f => f.cb !== cb || f.event !== event)
        }
        this.sendMessage = function (event,msg){
            this.socket.emit(event,msg);
        }
    }
    // Instance accessor
    Singleton.getInstance = function () {
        return instance || new Singleton();
    }

    return Singleton;
}());


export default Sockets;