import {endpoint, fetch_options} from "./helper";
import {Carrier} from "../../submodules/commons/types";

const url = `${endpoint}/countries`;

export async function createCarrierAPI(countryId:string, data:Carrier):Promise<{data?:Carrier,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${countryId}/carriers`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function updateCarrierAPI(countryId:string, carrierId:string,data:Carrier):Promise<{data?:Carrier,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${countryId}/carriers/${carrierId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export async function deleteCarrierAPI(countryId:string, carrierId:string){
    return fetch(`${url}/${countryId}/carriers/${carrierId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}