import {endpoint, fetch_options} from "./helper";
import {Server} from "../../submodules/commons/types/server";

const url = `${endpoint}/servers`;
export async function getServerListAPI():Promise<{data?:Server[],error?:string}>{
    return fetch(`${url}`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export async function createServerAPI(data:Server):Promise<{data?:Server,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function updateServerAPI(serverId:string,data:Server):Promise<{data?:Server,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${serverId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export async function deleteServerAPI(serverId:string){
    return fetch(`${url}/${serverId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}