import { combineReducers } from 'redux'
import tariffs from "./tariff-reducer";
import countries from "./country-reducer";
import modems from "./modem-reducer";
import servers from "./server-reducer";
import ports from "./port-reducer";
import customers from "./customer-reducer";
import userPorts from "./user-port-reducer";

export const rootReducer = combineReducers({
    tariffs,
    countries,
    customers,
    modems,
    servers,
    ports,
    userPorts
})
