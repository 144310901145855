import React, {lazy, Suspense} from 'react';
import logo from './logo.svg';
import './App.css';
import configureStore from "./store/configureStore";
import {Provider} from "react-redux";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Preloader from "./components/Preloader";
import Spinner from "./components/Spinner";
import BootstrapTemplate from "./template";
import SignIn from "./components/Auth/sign-in";
import SignUp from "./components/Auth/sign-up";
import BackEndPortsPage from "./pages/back-end-ports-page";
import TariffsPage from "./pages/tariffs-page";
import CountriesPage from "./pages/countries-page";
import CountryPage from "./pages/country-page";
import CustomerPage from "./pages/customer-page";

function App() {
  const store = configureStore()

  const IndexPage = lazy(()=>import("./pages/index"));
  const CustomersPage = lazy(()=>import("./pages/customers-page"));
  const CustomerPage = lazy(()=>import("./pages/customer-page"));
  const BackEndPorts = lazy(()=>import("./pages/back-end-ports-page"));
  const TariffsPage = lazy(()=>import("./pages/tariffs-page"));
  const CountriesPage = lazy(()=>import("./pages/countries-page"));
  const CountryPage = lazy(()=>import("./pages/country-page"));
  const ModemsPage = lazy(()=>import("./pages/modems-page"));
  const ServersPage = lazy(()=>import("./pages/servers-page"));
  return (
      <>
        <Provider store={store}>
          <BrowserRouter>
            <Preloader>
              <Suspense fallback={<Spinner/>}>
                <Routes>
                  <Route path='/login' element={<SignIn/>}/>
                  <Route path='/sign-up' element={<SignUp/>}/>

                  <Route path="/" element={<BootstrapTemplate/>}>
                    <Route path="/" element={<IndexPage/>}/>

                    <Route path="/customers" element={<CustomersPage/>}/>
                    <Route path="/customers/:customerId/:page" element={<CustomerPage/>}/>

                    <Route path="/ports" element={<BackEndPorts/>}/>
                    <Route path="/modems" element={<ModemsPage/>}/>
                    <Route path="/tariffs" element={<TariffsPage/>}/>
                    <Route path="/servers" element={<ServersPage/>}/>
                    <Route path="/countries" element={<CountriesPage/>}/>
                    <Route path="/countries/:countryId" element={<CountryPage/>}/>

                    <Route path="*" element={<Navigate to={'/customers'}/>}/>
                  </Route>

                </Routes>
              </Suspense>
            </Preloader>
          </BrowserRouter>
        </Provider>
      </>
  );
}

export default App;
