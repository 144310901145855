import {Country} from "../../submodules/commons/types/country";


const countries = (state:Country[] = [], action:any):Country[] => {
    const duplicate = state.some((row:any) => (row._id === action.data._id));
    switch (action.type) {
        case "ADD_COUNTRY":
        case "UPDATE_COUNTRY":

            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map((c:any) => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_COUNTRY":
            return state.filter(
                (c:any) => c._id !== action.data._id
            )
        case "LOAD_COUNTRIES":
            return action.data;
        default:
            return state;
    }


}
export const loadCountries = (data:any) => ({type: "LOAD_COUNTRIES", data})
export const updateCountry = (data:any) => ({type: "UPDATE_COUNTRY", data});
export const addCountry = (data:any) => ({type: "ADD_COUNTRY", data});
export const deleteCountry = (_id:string) => ({type: "DELETE_COUNTRY", data: {_id}});
export default countries;