import {importAllFromApp1API, importCountriesFromApp1API, logoutApi} from "../api";
import React, {useEffect, useMemo, useState} from "react";
import sockets from "../utils/sockets";
import {Link} from "react-router-dom";
import {ApiData} from "../api/helper";
import {toast} from "react-toastify";
import {loadCountries} from "../reducers/country-reducer";
import {useDispatch} from "react-redux";
import {loadServers} from "../reducers/server-reducer";
import {loadModems} from "../reducers/modem-reducer";

const TemplateHeader = () =>{
    const [headerInfo, setHeaderInfo] = useState<string>('');
    const dispatch = useDispatch();
    const onLogout = () =>{
        logoutApi().finally(()=>{
            window.location.href="/sign-in";
        });

    }
    const updateHeaderInfoListener = (data:string)=>{
        setHeaderInfo(data);
    }
    useEffect(()=>{
        sockets.getInstance().addListener('headerInfo',updateHeaderInfoListener);
        return ()=>{
            sockets.getInstance().removeListener('headerInfo',updateHeaderInfoListener);
        }
    })
    const syncAllDataWithApp1 = async ()=>{
        importAllFromApp1API()
            .then(({error, data})=>{
                if(!data){
                    toast.error(error)
                }else {
                    dispatch(loadCountries(data.countries));
                    dispatch(loadServers(data.servers));
                    dispatch(loadModems(data.modems));
                    toast.success('Data was synchronized!')
                }

            }).catch((err:Error)=>toast.error(err.message));

    }

    return(
        <header className="header col-auto">
            <div className="row g-0 align-items-center flex-nowrap justify-content-between">
                <div className="col-auto d-md-none">
                    <button className="toggle-menu">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </button>
                </div>
                <div className="col-auto d-none d-md-block">
                    <div className="welcome">Welcome <span className="name"></span></div>
                </div>

                <div className='col text-info text-center'>{headerInfo || ''}</div>
                <button type='button' className='col-1 btn btn-sm btn-danger' style={{width:100}} onClick={syncAllDataWithApp1}>Sync data with App1</button>
                <div className="col right-pan">

                    <a href={"#"} onClick={onLogout} className="logout text-white">Log Out</a>
                </div>
            </div>
        </header>
    )
}
export default TemplateHeader