import {endpoint, fetch_options} from "./helper";
import {Region} from "../../submodules/commons/types/region";

const url = `${endpoint}/countries`;

export async function createRegionAPI(countryId:string, data:Region):Promise<{data?:Region,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${countryId}/regions`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function updateRegionAPI(countryId:string, regionId:string,data:Region):Promise<{data?:Region,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${countryId}/regions/${regionId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export async function deleteRegionAPI(countryId:string, regionId:string){
    return fetch(`${url}/${countryId}/regions/${regionId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}