import {endpoint, fetch_options} from "./helper";
import {Tariff} from "../../submodules/commons/types/tariff";

const url = `${endpoint}/tariffs`;
export async function getTariffListAPI():Promise<{data?:Tariff[],error?:string}>{
    return fetch(`${url}`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export async function createTariffAPI(data:Tariff):Promise<{data?:Tariff,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function updateTariffAPI(tariffId:string,data:Tariff):Promise<{data?:Tariff,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${tariffId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export async function deleteTariffAPI(tariffId:string){
    return fetch(`${url}/${tariffId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}