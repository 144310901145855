import React from "react";
import {Link} from "react-router-dom";

const SignUp = props =>{
    return (
        <div className="app-box d-flex flex-column">
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <a href="#">
                                <img src="./assets/images/sign.svg" alt="Follow"/>
                            </a>
                        </div>
                        <div className="col-auto ml-auto">
                            <Link to="/">
                                <img src="./assets/images/close.svg" alt="Close"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
            <main className="main pt-4">
                <div className="container">
                    <div className="sub-title">Sign Up</div>

                    <form action="#" method="post" className="mt-5">
                        <div className="inp-group">
                            <label>E-mail</label>
                            <input type="email" name="email" placeholder="example@example.com"/>
                        </div>
                        <div className="inp-group">
                            <label>Name</label>
                            <input type="text" name="name" placeholder="You name here"/>
                        </div>
                        <div className="inp-group">
                            <label>Password</label>
                            <input type="text" name="password" placeholder="8 characters minimum"/>
                        </div>
                    </form>
                </div>
            </main>
            <footer className="footer mt-auto">
                <div className="container">
                    <div className="row g-0 align-items-center">
                        <a href="#" className="controls col">Create Account</a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default SignUp;