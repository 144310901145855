import {endpoint, fetch_options} from "./helper";
import {Port} from "../../submodules/commons/types";

const url = `${endpoint}/ports`;
export async function getPortListAPI():Promise<{data?:Port[],error?:string}>{
    return fetch(`${url}/admin`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export async function createPortAPI(data:any):Promise<{data?:Port,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function updatePortAPI(portId:string,data:Port):Promise<{data?:Port,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${portId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export async function testPortAPI(portId:string):Promise<{data?:string,error?:string}>{
    return fetch(`${url}/${portId}/test`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
export async function assignPortToModemAPI(portId:string,data:{userId:string}):Promise<{data?:Port,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${portId}/assign-port-to-modem`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function deletePortAPI(portId:string){
    return fetch(`${url}/${portId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}