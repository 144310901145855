import {endpoint, fetch_options} from "./helper";
import {User} from "../../submodules/commons/types/user";
import {Tariff} from "../../submodules/commons/types";

const url=`${endpoint}/customers`;

export function getRolesAPI() {
    return fetch(`${url}/roles`,fetch_options).then(r=>r.json());
}
export function getCustomersAPI(limit=300000):Promise<{data?:User[],error?:string}> {
    return fetch(url+'?limit='+limit,fetch_options).then(r=>r.json());
}
export function getCustomerAPI(customerId:string):Promise<{data?:User,error?:string}> {
    return fetch(`${url}/${customerId}`,fetch_options).then(r=>r.json());
}
export function getCustomerTokenAPI(customerId:string) {
    return fetch(`${url}/${customerId}/auth-token`,fetch_options).then(r=>r.json());
}
export function addCustomerAPI(data:User):Promise<{data?:User,error?:string}>{
    const body:string = JSON.stringify(data);
    return fetch(`${url}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateCustomerTariffAPI(userId:string,data: { tariff:Tariff }):Promise<{data?:User,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${userId}/update-tariff`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function assignTariffToCustomerAPI(_id:string,data: { tariffId:string }):Promise<{data?:User,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${_id}/assign-tariff`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function updateCustomerAPI(_id:string,data:User):Promise<{data?:User,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function updateAffiliateBonusAPI(_id:string,affiliateBonus:any){
    const body = JSON.stringify({affiliateBonus});
    return fetch(`${url}/${_id}/affiliate-bonus`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function payloadAffiliateBonusAPI(_id:string,affiliateBonus:any){
    const body = JSON.stringify({affiliateBonus});
    return fetch(`${url}/${_id}/payload-affiliate-bonus`,{...fetch_options, method:'POST',body}).then(r=>r.json())
}

export function deleteCustomerAPI(_id:string){
    return fetch(`${url}/${_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
export function setContractSignedAPI(customerId:string,contractSigned:any) {
    const body = JSON.stringify({contractSigned});
    return fetch(`${url}/${customerId}/setContractSigned`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function createFarmerAPI(customerId:string) {
    const body = JSON.stringify({});
    return fetch(`${url}/${customerId}/create-farmer`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}