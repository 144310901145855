import {Tariff} from "../../submodules/commons/types/tariff";

const tariffs = (state = [], action:any):Tariff[] => {
    const duplicate = state.some((row:any) => (row._id === action.data._id));
    switch (action.type) {
        case "ADD_TARIFF":
        case "UPDATE_TARIFF":

            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map((c:any) => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_TARIFF":
            return state.filter(
                (c:any) => c._id !== action.data._id
            )
        case "LOAD_TARIFFS":
            return action.data;
        default:
            return state;
    }


}
export const loadTariffs = (data:any) => ({type: "LOAD_TARIFFS", data})
export const updateTariff = (data:any) => ({type: "UPDATE_TARIFF", data});
export const addTariff = (data:any) => ({type: "ADD_TARIFF", data});
export const deleteTariff = (_id:string) => ({type: "DELETE_TARIFF", data: {_id}});
export default tariffs;