import {Server} from "../../submodules/commons/types/server";

const servers = (state = [], action:any):Server[] => {
    const duplicate = state.some((row:any) => (row._id === action.data._id));
    switch (action.type) {
        case "ADD_SERVER":
        case "UPDATE_SERVER":

            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map((c:any) => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_SERVER":
            return state.filter(
                (c:any) => c._id !== action.data._id
            )
        case "LOAD_SERVERS":
            return action.data;
        default:
            return state;
    }


}
export const loadServers = (data:any) => ({type: "LOAD_SERVERS", data})
export const updateServer = (data:any) => ({type: "UPDATE_SERVER", data});
export const addServer = (data:any) => ({type: "ADD_SERVER", data});
export const deleteServer = (_id:string) => ({type: "DELETE_SERVER", data: {_id}});
export default servers;