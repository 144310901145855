export const fetch_options = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
};
export const endpoint = window.location.origin+"/api/v1";

export interface ApiData{
    data?:any,
    error?:string,
    result?:string,
    status?:number
}