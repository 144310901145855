const customers =  (state=[], action) => {
    const duplicate = state.some(row=>(row._id===action.data._id));
    switch (action.type) {
        case "LOAD_CUSTOMERS":
            return [
                ...action.data
            ];
        case "ADD_CUSTOMER":
        case "EDIT_CUSTOMER":

            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_CUSTOMER" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default customers;

export const loadCustomers = data =>({type:'LOAD_CUSTOMERS', data})
export const addCustomer = data =>({type:'ADD_CUSTOMER', data})
export const updateCustomer = data =>({type:'EDIT_CUSTOMER', data})
export const deleteCustomer = _id =>({type:'DELETE_CUSTOMER', data:{_id}})