import {endpoint, fetch_options} from "./helper";
export function logoutApi() {
    return fetch('/logout',{method:'POST'});
}
export async function checkLoginApi(login:string) {
    const body = JSON.stringify({login})
    return fetch(`/api/v1/auth/check-login`,{...fetch_options, method:'POST', body}).then(r=>r.json());
}
export async function checkCredentials(login:string, password:string, code?:string){
    try{
        const result = await fetch(
            `${endpoint}/auth/check-credentials`,
            {
                ...fetch_options,
                body:JSON.stringify({login,password, code})
                ,method:'POST'
            });
        const json = await result.json()
        json.status = result.status;
        return Promise.resolve(json);
    }catch (err){
        return Promise.reject(err);
    }
}
export async function sendVerificationCodeApi(login:string) {
    const body = JSON.stringify({login})
    return fetch(`/api/v1/auth/send-verification-code`,{...fetch_options, method:'POST', body}).then(r=>r.json());
}