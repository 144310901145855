import {endpoint, fetch_options} from "./helper";
import {Modem} from "../../submodules/commons/types";

const url = `${endpoint}/modems`;
export async function getModemListAPI():Promise<{data?:Modem[],error?:string}>{
    return fetch(`${url}/admin`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export async function createModemAPI(data:Modem):Promise<{data?:Modem,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export async function updateModemAPI(modemId:string,data:Modem):Promise<{data?:Modem,error?:string}>{
    const body = JSON.stringify(data);
    return fetch(`${url}/${modemId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export async function deleteModemAPI(modemId:string){
    return fetch(`${url}/${modemId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}